<script setup>
import { sidebarStore } from '@/stores/sidebar'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { permissionsStore } from '@/stores/permissions'
import { api, sbapibackoffice } from '@/api'
import { reactive, ref } from '@vue/reactivity'
import router from './../router'
import { inject, onMounted, onBeforeUnmount } from '@vue/runtime-core'

const sidebar = sidebarStore()
const spinner = spinnerStore()
const navbar = navbarStore()
const permissions = permissionsStore()
const toast = inject('$toast')
let resetPassword = ref(false)

let ready = ref(false)
   
const user = reactive({
  email: '',
  password: ''
})
onMounted(() => {
  // if its not logged
  if (!localStorage.getItem('userId')) {
    navbar.user = null
    if (document.getElementById('mySidebar'))
      document.getElementById('mySidebar').style.width = '0'
    if (document.getElementById('main'))
      document.getElementById('main').style.marginLeft = '0'
  }
  ready.value = true
  sidebar.hide()
  window.addEventListener('keyup', function(event) {
    if (ready && user.email && user.password && event.keyCode === 13 ) { 
      login()
    }
  })
      
})
/**
		 * check if the form is valid
		 * @returns true is the form is valid, otherwise false
		 */
onBeforeUnmount(() => {
  ready = false
  window.removeEventListener('keyup', ()=>{}, true) 
})
const isFormValid = () => {
  const isValid = user.email.includes('@cving.com')
  if (!isValid) toast.error('Non puoi accedere con un indirizzo email non CVing')
  return isValid
}
const sendResetPassword = () => {
  spinner.show()

  api.post('/ask-password-reset-candidates', { email: user.email })
    .then(() => {
      toast.success('Ti è stata inviata una mail con le istruzioni per resettare la password')
      resetPassword.value = false
    })
    .catch(() => {
      toast.error('Non è stato possibile inviare la mail')
    })
    .then(() => {
      spinner.hide()
    })
}

const login = async () => {
  if (isFormValid()) {
    spinner.show()
    ready.value = false

    api.post(
      '/login',
      {
        email: user.email,
        password: user.password,
        account: 0,
        dlt: '',
        id_token: ''
      },
      { withCredentials: true }
    )
      .then(async (response) => {
        localStorage.setItem('userId', response.data.uid)
        localStorage.setItem('userEmail', response.data.email)
        localStorage.setItem('user', response.data.first_name + ' ' + response.data.last_name)

        await getUserData()
        await getApiCSRFToken()

        navbar.user = response.data.first_name + ' ' + response.data.last_name
        router.push({ path: '/welcome' })
        sidebar.open()

        spinner.hide()
      })
      .catch((error) => {
        console.error('error', error)
        toast.error(error.response.data.error)
        spinner.hide()
        ready.value = true
      })
  }
}

const getUserData = async () => {
  permissions.clear()
      
  try {
    const { data } = await sbapibackoffice.get('/get-user-data')

    permissions.roles = data.roles
    permissions.permissions = data.permissions
    localStorage.setItem('roles', JSON.stringify(data.roles))
    localStorage.setItem('privileges', JSON.stringify(data.privileges))
  } catch (error) {
    console.error('error', error)
    toast.error(error.response.data.error)
  }
}

const getApiCSRFToken = async () => {

  try {
    await api.get('/auth/get-csrf-token')
  } catch (error) {
    console.error('error', error)
    toast.error(error.response.data.error)
  }
}
/*
    const getStorageCSRFToken = async () => {

      try {
        await storage.get('/auth/get-csrf-token')
      } catch (error) {
        console.error('error', error)
        toast.error(error.response.data.error)
      }
    }

     */

const showResetPassword = () => {
  resetPassword.value = true
}
const hideResetPassword = () => {
  resetPassword.value = false
}
</script>

<template>
  <main class="login">
    <div class="headerSignup">
      <div href="/" aria-current="page" class="logo">
        <img src="../assets/cving.full.svg" alt="CVing">
      </div>
    </div>
    <transition name="slide" type="animation">
      <div v-if="ready" class="login-box" @keydown.enter.prevent="login">
        <div id="bm" />
        <div style="width: 325px;">
          <h1 style="color:white; margin-bottom:5px" v-text="resetPassword? 'Recupera password':'Sign Up'" />
    
          <form class="form" action="/referrals" @submit.prevent="login()">
            <div class="container-input">
              <material-input
                :id="'email_input'"
                v-model="user.email"
                type="email"
                label="Email"
                placeholder="Email"
                :value="user.email"
                :required="true"
                @input="(e) => (user.email = e)"
              />
            </div>
            <div v-if="!resetPassword" class="container-input">
              <material-input
                :id="'psw_input'"
                type="password"
                label="Password"
                autocomplete="off"
                :value="user.password"
                :required="true"
                @input="(e) => (user.password = e)"
              />
            </div>
            <p v-else class="recovery-text">
              Inserisci la tua email per richiedere una nuova password.
            </p>
            <material-button
              v-if="resetPassword"
              type="submit"
              :disabled="user.email === ''"
              text="Richiedi password"
              :class="{isBtnDisabled : user.email === ''}"
              @click="sendResetPassword()"
            />
            <material-button v-else :class="{isBtnDisabled : user.email === '' || user.password === ''}" type="submit" text="Accedi con le credenziali" />
          </form>
        </div>
      </div>
    </transition>
    <button v-if="ready && !resetPassword" class="forgotten btn-show-forgotten" @click="showResetPassword()">
      Password dimenticata?
    </button>
    <button v-if="ready && resetPassword" class="forgotten" @click="hideResetPassword()">
      Torna al login
    </button>
  </main>
</template>

<style lang="scss" scoped>
@use '../assets/scss/login';
form label {
	font-weight: normal;
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  top: 0px;
  font-size: 14px;
  left: 2px;
  color: var(--accented);
  transition: top 0.5s ease-in-out;
}
.container-input{
  position: relative;
  min-width: 220px;
}
div.reset-password {
	width: 100%;
}
</style>
<style>
input:-webkit-autofill + span +label.placeholder-label,
input:-webkit-autofill:hover + span +label.placeholder-label,
input:-webkit-autofill:focus + span + label.placeholder-label,
input:-webkit-autofill:active + span + label.placeholder-label {
	margin-top: -20px!important;
  transition: all 1000ms ease;
}
input:-webkit-autofill + span.bar.active,
input:-webkit-autofill:hover + span.bar.active,
input:-webkit-autofill:focus + span.bar.active,
input:-webkit-autofill:active + span.bar.active {
	background: #bcde42!important;
}
</style>
